import React, { ReactElement, useState, useRef, useCallback } from 'react';
import { Select as AntSelect, Spin } from 'antd';
import {
  LabeledValue,
  SelectProps as AntSelectProps,
  SelectValue,
  RefSelectProps,
} from 'antd/lib/select';
import classNames from 'classnames';
import SelectOption, { SelectOptionProps } from './SelectOption';
import omit from 'lodash/omit';
import { Release } from 'models/Release';
import { SelectProps as AntdSelectProps } from 'antd/lib/select';
import { Typography } from 'component-library/src';

declare type RawValue = string | number;

export type SelectOptionPropsWithFormValue = SelectOptionProps & {
  optionKey?: RawValue;
  value: RawValue;
};

interface SelectOptionGroupProps {
  groupTitle: string;
  options?: SelectOptionPropsWithFormValue[];
}

export type SelectCTAProps = SelectOptionProps & {
  onClick?: React.MouseEventHandler;
};

export interface SelectProps<T extends SelectValue>
  extends Omit<AntSelectProps<T>, 'options' | 'children'> {
  options?: SelectOptionPropsWithFormValue[];
  groupedOptions?: SelectOptionGroupProps[];
  ctas?: Omit<SelectCTAProps, 'value'>[];
  testId?: string;
  autoHeight?: boolean;
  optionSize?: AntdSelectProps<unknown>['size'];
  optionIconSize?: AntdSelectProps<unknown>['size'];
  isFistOptionSelectAll?: boolean;
  onInfiniteScroll?: () => void;
  infiniteScrollLoading?: boolean;
  dropdownClassName?: string;
}

const defaultFilterOption = (inputValue: string, option: unknown): boolean => {
  const includes = (option as Release['title'])?.title
    ?.toLowerCase()
    .includes(inputValue?.toLowerCase());
  return includes === undefined ? false : includes;
};

function Select<T extends SelectValue>(props: SelectProps<T>): ReactElement {
  const {
    options = [],
    groupedOptions = [],
    ctas,
    testId = 'custom-select-dropdown',
    filterOption = defaultFilterOption,
    autoHeight = false,
    className,
    optionSize, //would be set to 'middle' as default value once the optionSize is added everywhere
    optionIconSize,
    value: selectedValue,
    onPopupScroll,
    onInfiniteScroll,
    infiniteScrollLoading,
    loading,
    dropdownClassName,
    ...rest
  } = props;
  const selectRef = useRef<RefSelectProps>(null);
  const [isSelected, setIsSelected] = useState(Boolean(selectedValue));
  const ctaSize = Boolean(ctas) ? (optionSize === 'small' ? 44 : 56) : 0;

  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const { currentTarget } = e;
      onPopupScroll?.(e);
      if (
        currentTarget.scrollTop + currentTarget.offsetHeight ===
        currentTarget.scrollHeight
      ) {
        onInfiniteScroll?.();
      }
    },
    [onPopupScroll, onInfiniteScroll]
  );

  return (
    <AntSelect<T>
      ref={selectRef}
      virtual={false}
      onPopupScroll={handleScroll}
      value={selectedValue}
      listHeight={240 - ctaSize}
      data-testid={testId}
      popupClassName={classNames({
        'custom-select-dropdown': true,
        [`${dropdownClassName}`]: dropdownClassName,
      })}
      filterOption={rest.showSearch ? filterOption : undefined}
      menuItemSelectedIcon={null}
      className={classNames({
        'custom-select': true,
        'custom-select-selected': isSelected,
        'custom-select-auto-input-height': autoHeight,
        [`${className}`]: className,
      })}
      loading={loading || Boolean(infiniteScrollLoading)}
      defaultActiveFirstOption={false}
      onSelect={(): void => setIsSelected(true)}
      onClear={(): void => setIsSelected(false)}
      dropdownRender={(menu): JSX.Element => (
        <>
          {menu}
          {infiniteScrollLoading && <Spin className="w-100" />}
          {Boolean(ctas) &&
            ctas?.map((cta) => (
              <button
                key={cta.label}
                className="custom-select-cta"
                onClick={(e) => {
                  selectRef.current?.focus();
                  cta.onClick?.(e);
                  selectRef.current?.blur();
                }}
                title={cta.label}
              >
                {/* 'large' needs to be removed when the optionSize of select component is added everywhere */}
                <SelectOption
                  {...omit(cta, 'key')}
                  isCtaButton
                  imageSize={optionSize ?? 'large'}
                  optionSize={optionSize}
                  optionIconSize={optionIconSize}
                />
              </button>
            ))}
        </>
      )}
      {...rest}
    >
      {options?.map((option, index) => (
        <AntSelect.Option
          className={classNames({
            'custom-select-item': true,
            [`custom-select-item-size-${optionSize}`]: Boolean(optionSize),
            'custom-select-item-checkbox':
              rest.mode === 'multiple' ||
              (rest.isFistOptionSelectAll && index === 0),
          })}
          data-testid="custom-select-item"
          {...omit(option, 'disabledTooltipText')}
          key={`custom-select-${option.optionKey}`}
          label={undefined}
          title={option.label}
        >
          <SelectOption
            {...omit(option, 'key', 'testId', 'data-testid')}
            testId={`custom-select-item-${testId}-${index}`}
            imageSize={rest.size}
            optionSize={optionSize}
            optionIconSize={optionIconSize}
            mode={rest.mode}
            isSelectedOption={
              Array.isArray(selectedValue)
                ? selectedValue.includes(
                    option?.value as RawValue & LabeledValue
                  )
                : selectedValue === option.value
            }
          />
        </AntSelect.Option>
      ))}
      {groupedOptions?.map((option, groupIndex) => (
        <AntSelect.OptGroup
          key={`custom-select-group-${groupIndex}`}
          className="custom-select-group"
          data-testid="custom-select-group"
          label={
            <Typography
              size="s"
              type="body"
              weightLevel="500"
              customClass="pt-2 pb-2"
            >
              {option.groupTitle}
            </Typography>
          }
        >
          {option?.options?.map((option, optionIndex) => (
            <AntSelect.Option
              className={classNames({
                'custom-select-item': true,
                [`custom-select-item-${optionSize}`]: Boolean(optionSize),
              })}
              data-testid="custom-select-item"
              title={option.label}
              {...option}
              key={option.optionKey}
            >
              <SelectOption
                {...option}
                imageSize={rest.size}
                optionSize={optionSize}
                optionIconSize={optionIconSize}
                testId={`custom-select-item-${testId}-group-${groupIndex}-${optionIndex}`}
                mode={rest.mode}
                isSelectedOption={
                  Array.isArray(selectedValue)
                    ? selectedValue.includes(
                        option?.value as RawValue & LabeledValue
                      )
                    : selectedValue === option.value
                }
              />
            </AntSelect.Option>
          ))}
        </AntSelect.OptGroup>
      ))}
    </AntSelect>
  );
}

export default Select;
