import { ApiSpec } from 'specs/api-spec';

export interface AlgoliaSecuredApiKeyResponse {
  apiKey: string;
  validUntil: number;
}

export interface AlgoliaRelease {
  id?: string;
  upc?: string;
  title?: string;
  createdAt?: number;
  releaseDate?: number;
  titleVersion?: string;
  submissionDate?: number;
  resizedArtworkUrl?: string;
  status?: ApiSpec['schemas']['ReleaseStatusResponse']['combinedStatus'];
  sharedWithUserIds?: string[] | null;
  userId?: string;
  isShared?: boolean;
}

export enum AlgoliaSharedStatus {
  MINE = 'MINE',
  SHARED_WITH_ME = 'SHARED_WITH_ME',
  SHARED_BY_ME = 'SHARED_BY_ME',
}

export interface AlgoliaSearchRequest {
  sharedStatus?: AlgoliaSharedStatus;
  status?: string;
  hitsPerPage?: number;
  page?: number;
  query?: string;
}
