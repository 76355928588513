import React from 'react';
import { TrackEventHandler } from '../types';
import ConfirmModal from './ConfirmModal';

export interface ConfirmDeleteModalProps {
  onCancel: () => void;
  onDelete: () => void;
  heading: string;
  body?: string;
  cancelButtonText: string;
  deleteButtonText: string;
  deleteInProgress?: boolean;
  trackEvent?: TrackEventHandler;
  size?: 's' | 'm';
}

const ConfirmDeleteModal: React.FC<React.PropsWithChildren<
  ConfirmDeleteModalProps
>> = ({
  deleteInProgress = false,
  onCancel,
  onDelete,
  heading,
  body,
  cancelButtonText,
  deleteButtonText,
  trackEvent,
  size = 'm',
}: ConfirmDeleteModalProps) => {
  return (
    <ConfirmModal
      okayInProgress={deleteInProgress}
      onCancel={onCancel}
      onOkay={onDelete}
      heading={heading}
      body={body}
      okayButtonText={deleteButtonText}
      cancelButtonText={cancelButtonText}
      trackEvent={trackEvent}
      size={size}
      variant="delete"
    />
  );
};

export default ConfirmDeleteModal;
