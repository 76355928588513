import React, { ReactElement, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Grid } from 'antd';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { ReactComponent as Logo } from 'assets/img/logo.svg';
import { useAuth } from 'hooks/useAuth';
import { isMdScreen } from 'helper/styling/styling';
import { MobileMenuClosed } from 'assets/img';
import MobileMenuDrawer from './components/MobileMenuDrawer';
import { Header } from 'antd/lib/layout/layout';
import Container from 'components/container/Container';
import DesktopSiderNav from 'components/sider-nav/SiderNav';
import routesConfig from 'config/routes';

interface NavProps {
  currKey: string;
  handleClick: MenuClickEventHandler;
}

enum MenuType {
  Mobile,
  Desktop,
}

const accountSubMenuKey = 'sub1';

const Nav = ({ currKey, handleClick }: NavProps): ReactElement => {
  const { signout } = useAuth();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const [menuType, setMenuType] = useState<MenuType>();
  const [mobileMenuOpen, setMobileMenuState] = useState(false);
  const [openKeys, setOpenKeys] = useState<React.Key[] | undefined>(undefined);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const type = isMdScreen(screens) ? MenuType.Desktop : MenuType.Mobile;
    setMenuType(type);
  }, [screens, menuType]);

  const setMobileMenuOpen = () => {
    setMobileMenuState(!mobileMenuOpen);
  };

  const handleSignout = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { search, pathname, ...navigateOptions } = location;
    navigate(pathname, {
      ...navigateOptions,
      state: 'logout',
    });
    signout();
  };

  const handleOpenKeys = (keys?: React.Key[]) => {
    setOpenKeys(keys);
  };

  useEffect(() => {
    if (
      routesConfig
        .filter(
          (item) =>
            item.pageKey === 'subscription' ||
            item.pageKey === 'royaltyPayouts' ||
            item.pageKey === 'profileDetails'
        )
        .map((item) => item.pageKey)
        .includes(currKey) &&
      !openKeys?.includes(accountSubMenuKey)
    ) {
      setOpenKeys([accountSubMenuKey]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currKey]);

  const navMenuHomeLogo = (
    <div className="logo">
      <Link to={'/'} data-testid="nav-logo" title="Home link">
        <span className="sr-only">Home link</span>
        <Logo />
      </Link>
    </div>
  );

  return (
    <>
      {menuType === MenuType.Mobile ? (
        <Header>
          <Container>
            {navMenuHomeLogo}

            <div className="mobile-nav-menu-layout">
              <MobileMenuClosed
                className="menu-closed__mob"
                data-testid="mobile-hamburger-menu-closed"
                onClick={setMobileMenuOpen}
              />
              <MobileMenuDrawer
                openKeys={openKeys}
                isOpen={mobileMenuOpen}
                currentMenuKey={currKey}
                setOpenKeys={handleOpenKeys}
                handleSignOut={handleSignout}
                handleItemClick={handleClick}
                setIsMenuOpen={setMobileMenuOpen}
              />
            </div>
          </Container>
        </Header>
      ) : (
        <DesktopSiderNav
          openKeys={openKeys}
          currentMenuKey={currKey}
          setOpenKeys={handleOpenKeys}
          handleSignOut={handleSignout}
          handleMenuItemClickFn={handleClick}
        />
      )}
    </>
  );
};

export default Nav;
