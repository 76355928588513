import { Axios, AxiosResponse } from 'axios';
import { instance } from 'helper/api/api';
import algoliasearch, { SearchClient } from 'algoliasearch';
import { SearchResponse } from '@algolia/client-search';
import dayjs from 'dayjs';
import {
  AlgoliaSearchRequest,
  AlgoliaSecuredApiKeyResponse,
  AlgoliaRelease,
  AlgoliaSharedStatus,
} from 'models/Algolia';
import { Release } from 'models/Release';

export const ALGOLIA_SECURED_API_STORAGE_KEY = 'algolia-secured-api-key-web';
export const ALGOLIA_RELEASES_INDEX_NAME = 'releases';
export const ALGOLIA_RELEASES_STATUS_ALL = 'ALL';
export const ALGOLIA_RELEASE_DEFAULT_PAGE_SIZE = 12;

export class AlgoliaService {
  private musicHubApiAxiosInstance: Axios;
  private algoliaClientFactory: (appId: string, apiKey: string) => SearchClient;

  constructor(
    musicHubApiAxiosInstance: Axios,
    algoliaClientFactory = algoliasearch
  ) {
    this.musicHubApiAxiosInstance = musicHubApiAxiosInstance;
    this.algoliaClientFactory = algoliaClientFactory;
  }

  getAlgoliaApiKeyLocalStorage = (): string | null =>
    localStorage.getItem(ALGOLIA_SECURED_API_STORAGE_KEY);

  setAlgoliaApiKeyLocalStorage = (
    apiKeyResponse: AlgoliaSecuredApiKeyResponse
  ) =>
    localStorage.setItem(
      ALGOLIA_SECURED_API_STORAGE_KEY,
      JSON.stringify(apiKeyResponse)
    );

  fetchAlgoliaApiKey = async (): Promise<
    AxiosResponse<AlgoliaSecuredApiKeyResponse>
  > => await this.musicHubApiAxiosInstance.post(`search/apiKey`);

  getAlgoliaApiKey = async (): Promise<string> => {
    const securedApiKey = this.getAlgoliaApiKeyLocalStorage();

    if (
      !securedApiKey ||
      new Date().getTime() > JSON.parse(securedApiKey).validUntil
    ) {
      const { data } = await this.fetchAlgoliaApiKey();
      this.setAlgoliaApiKeyLocalStorage(data);
      return data.apiKey;
    }

    return JSON.parse(securedApiKey).apiKey;
  };

  deleteAlgoliaKey = () => {
    localStorage.removeItem(ALGOLIA_SECURED_API_STORAGE_KEY);
  };

  getAlgoliaClient = async (): Promise<SearchClient> => {
    const key = await this.getAlgoliaApiKey();
    return this.algoliaClientFactory(
      process.env.REACT_APP_ALGOLIA_APP_ID || '',
      key
    );
  };

  constructReleaseFacetFilters(
    params: AlgoliaSearchRequest,
    userId: string
  ): string[][] {
    const facetFilters: string[][] = [];

    if (params.status && params.status !== ALGOLIA_RELEASES_STATUS_ALL) {
      facetFilters.push([`status:${params.status}`]);
    }
    if (params.sharedStatus === AlgoliaSharedStatus.MINE) {
      facetFilters.push([`userId:${userId}`]);
    }
    if (params.sharedStatus === AlgoliaSharedStatus.SHARED_BY_ME) {
      facetFilters.push([`userId:${userId}`]);
      facetFilters.push([`isShared:true`]);
    }
    if (params.sharedStatus === AlgoliaSharedStatus.SHARED_WITH_ME) {
      facetFilters.push([`sharedWithUserIds:${userId}`]);
      facetFilters.push([`isShared:true`]);
    }
    return facetFilters;
  }

  constructReleaseAlgoliaRequest(
    params: AlgoliaSearchRequest,
    facetFilters: string[][]
  ) {
    const requestParams = {
      facetFilters,
      facets: ['status', 'userId', 'sharedWithUserIds', 'isShared'],
      hitsPerPage: params.hitsPerPage ?? ALGOLIA_RELEASE_DEFAULT_PAGE_SIZE,
      maxValuesPerFacet: 10,
      page: params.page ?? 0,
      query: params.query ?? '',
    };

    return {
      indexName: ALGOLIA_RELEASES_INDEX_NAME,
      params: requestParams,
    };
  }

  async searchReleases(
    releaseSearchRequest: AlgoliaSearchRequest,
    userId: string
  ) {
    const client = await this.getAlgoliaClient();
    return client.search<AlgoliaRelease>([
      this.constructReleaseAlgoliaRequest(
        releaseSearchRequest,
        this.constructReleaseFacetFilters(releaseSearchRequest, userId)
      ),
      this.constructReleaseAlgoliaRequest(
        releaseSearchRequest,
        this.constructReleaseFacetFilters(
          { ...releaseSearchRequest, page: 0, status: undefined },
          userId
        )
      ),
    ]);
  }

  algoliaReleaseToRelease = (
    algoliaRelease: SearchResponse<AlgoliaRelease>['hits'][0]
  ): Release => {
    return {
      upc: algoliaRelease.upc,
      id: algoliaRelease.objectID,
      userId: algoliaRelease.userId,
      createdDate: dayjs(algoliaRelease.createdAt),
      releaseDate: algoliaRelease.releaseDate
        ? dayjs(algoliaRelease.releaseDate)
        : undefined,
      submissionDate: algoliaRelease.submissionDate
        ? dayjs(algoliaRelease.submissionDate)
        : undefined,
      releaseStatus: {
        combinedStatus: algoliaRelease.status,
      },
      title: {
        title: algoliaRelease.title,
        version: algoliaRelease.titleVersion,
      },
      artwork: {
        file: {
          resizedImgUri: algoliaRelease.resizedArtworkUrl,
          type: '',
          name: '',
          uid: '',
          size: 0,
        },
      },
    };
  };

  algoliaReleasesToReleases = (
    algoliaReleases: SearchResponse<AlgoliaRelease>['hits']
  ): Release[] => algoliaReleases.map(this.algoliaReleaseToRelease.bind(this));
}

export default new AlgoliaService(instance);
